import '../css/custommodal.css'
import '../css/minigallery.css'
import {Image} from 'react-bootstrap';
import { ArrowLeftSquare, ArrowRightSquare, XCircle } from 'react-bootstrap-icons'
import React, { useState } from 'react';

export type BigGalleryProps = {
    show: boolean;
    folder: string;
    images: string[];
    currentimage: string;
    closeFct: Function;
    delFct: Function;
    tischId: number;
}

export default function BigGallery2({ data }: { data: BigGalleryProps }) {
    

    const [idx, setIdx] = useState(0);
    const [loaded, setLoaded] = useState<boolean>(false);
  
    React.useEffect((): any => {       
        for (let i = 0; i < data.images.length;i++)
        {           
            if (data.images[i] === data.currentimage)
            {             
                setIdx(i);
                break;
            }
        }
        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                data.closeFct();
            }
        };
        window.addEventListener('keydown', handleEsc);
     
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [data]);

    function rightClicked(){
        setLoaded(false);
        setIdx((idx + 1) % data.images.length);
    }

    function leftClicked() {
        setIdx((idx + data.images.length - 1) % data.images.length);
        setLoaded(false);
    }


    return (
        <> 
        { data.show && 
            <>
            <div className="outer2">
               <div className="middle2">   
                    <Image src={data.folder + "/thumbnails/" + data.images[idx]} />  
               </div>              
           </div>
           <div className="outer">
               <div className={"middle" + (loaded?"":" imghidden")}>   
                    <Image  onLoad={() => {setLoaded(true)}} src={data.folder + "/" + data.images[idx]} />  
               </div>
               { data.images.length > 1 && <>
               <div id="leftGalleryBtn" onClick={leftClicked}><div><ArrowLeftSquare className="GalleryNavIcon"/></div></div>
               <div id="rightGalleryBtn" onClick={rightClicked}><div><ArrowRightSquare className="GalleryNavIcon"/></div></div></> }
               <XCircle id="GalleryCloseBtn" onClick={() => data.closeFct()}></XCircle>
           </div>
           </>
       }
       </>
       )
}