import React from 'react'
import '../../css/custommodal.css'

type LoadingModalProps = {
    show: boolean;
}

export default function LoadingModal({show} : LoadingModalProps) {
    
    return (
     <> 
     { show && 
        <div className="outer">
            <div className="middle">
                <div className="inner">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                </div>
            </div>
        </div>
    }
    </>
    )
}
