
import { GetUsersResponse, UserLight } from "../Data/RestResponseData";
import { RestInterface } from "./RestInterface";
import Keycloak from 'keycloak-js';
import { UserButtonProps } from "../Components/UserButton";


export class SessionService {

    usersResponse: GetUsersResponse | null = null;
    initialized: boolean = false;
    isInitializing: boolean = false;


    usersLoaded: boolean = false;
    keycloakInited: boolean = false;
    initError: boolean = false;

    keycloakInstance: Keycloak | null = null;

    userSelf: UserLight | null = null;

    init(): Promise<boolean>
    {
       
        
            
           if (this.initialized || this.isInitializing)
           {
                throw new Error("SessionService must be initialized only once!")
           }
           else
           {
                this.keycloakInstance = new Keycloak({
                    realm: "KaslaibeRealm",
                    url: process.env.NODE_ENV === 'development' ? "http://localhost:8080/" : "https://kaslaibepatrol.de:9443/",
                    clientId: "KaslaibeLoginApp"
                  });
                this.keycloakInstance.onTokenExpired = () => {
                    //console.log("token expired at " + (new Date().toTimeString()) + ", instance: " + (this.keycloakInstance?"VALID":"INVALID"))
                    this.keycloakInstance?.updateToken().then(success => {
                        console.log("token refreshed")
                    }).catch(err => {
                        console.log("could not update tokem, err: " + err)
                    });
                };   
                this.isInitializing = true;


                var promise = new Promise<boolean>((resolve, reject) => {
                    this.keycloakInstance?.init({
                    onLoad: 'login-required' // Supported values: 'check-sso' , 'login-required'
                    
                    }).then((auth) => {

                    if (this.keycloakInstance && this.keycloakInstance.tokenParsed?.exp)
                    {
                        //console.log("token will expire at " + new Date(this.keycloakInstance.tokenParsed.exp * 1000).toTimeString())
                    }
                    
                    if (!auth) {
                      //alert("keycloak not authed, reloading with 'window.location.reload()'")
                      window.location.reload();
                    } else {
                        
                        this.keycloakInstance?.loadUserProfile().then(() => {
                           // console.log("loading user profile finished, load users from backend")
                           // console.log("Authed User: " + this.keycloakInstance?.profile?.username)
                        RestInterface.getAllUsers().then(resp => 
                        { 
                            //console.log("loaded users")
                            this.usersResponse = resp.data
                            this.initUsers();
                            resolve(true);
    
                        }).catch(error => {
                            console.error("loading users failed")
                            reject();
                        })
                      })
                    }
                  }, () => {
                    
                    console.error("Authentication Failed");
                  });

            });

            
            
            return promise;
           }
 
    }

    public getUserById(userId: number) : UserLight | undefined
    {
        return this.getAllUsers().find(e => e.id === userId);       
    }

    public getUserButtonProps(userId: number) : UserButtonProps
    {
        let usr = this.getAllUsers().find(e => e.id === userId);
        if (usr)
        {
            return {userId: userId, username: usr.username, avatarHash: usr.avatarHash, size: 50};
        }
        return {userId: 0, username: "unknown", avatarHash: "", size: 50};
    }

    public getUsernameById(id: number) : string
    {
        this.getAllUsers().forEach(u => {
            if (u.id === id)
            {
                return u.username;
            }
        })
        return "ERROR";
    }

    public logout() : void
    {
        this.keycloakInstance?.logout();
    }

    public startTokenAutoRefresh() : void
    {
        /*
        if (this.keycloakInstance)
        {

            console.log("startTokenAutoRefresh at " + (new Date().toTimeString()))
            if (this.keycloakInstance.tokenParsed?.exp)
            {
                console.log("token will expire at " + new Date(this.keycloakInstance.tokenParsed.exp * 1000).toTimeString())
            }
            
            
            //this.keycloakInstance.onTokenExpired = this.callBack;      
                this.keycloakInstance.onTokenExpired = () => {
                    console.log("token is expired at " + (new Date().toTimeString()))
                };
        }*/
    }

    private callBack(): void
    {
        //console.log("token expired at " + (new Date().toTimeString()) + ", instance: " + (this.keycloakInstance?"VALID":"INVALID"))
        this.keycloakInstance?.updateToken().then(success => {
            //console.log("token refreshed")
        }).catch(err => {
            //console.log("could not update tokem, err: " + err)
        });
    }

    private initUsers(): void
    {
        if (this.usersResponse)
        {
            this.usersResponse.allUsers.forEach(user => {
                if (user.id === this.usersResponse?.selfId)
                {
                    this.userSelf = user;
                }
            });
        }
    }

    public getSelf() : UserLight | null
    {
        return this.userSelf;
    }

    public updateToken() : Promise<boolean>
    {
        let promise = this.keycloakInstance?.updateToken();
        if (promise)
        {
            return promise;
        }
        return new Promise<boolean>((resolve, reject) => {
            reject(false);
        });
    }

    public isTokenExpired() : boolean
    {
        if (this.keycloakInstance)
        {
            return this.keycloakInstance.isTokenExpired();
        }
        return false;
    }

    public getToken() : string | undefined
    {
        return this.keycloakInstance?.token;
    }


    IsAdmin(): boolean
    {
        if (this.keycloakInstance && this.keycloakInstance.authenticated) 
        {
            return this.keycloakInstance.hasResourceRole("client_admin")
        }
        return false;
    }

    public getAllUsers(): UserLight[]
    {
        if (this.usersResponse)
        {
            return this.usersResponse.allUsers;
        }
        return [];
    }

}