import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { sessionService } from "../App";
import { RestInterface } from "../Services/RestInterface";


type GalleryProps = {
    folder: string;
    images: string[];
    stammtischId: number;
    clickfct: Function;
    imgPlusClicked: Function;
    imgagesDeletedCallback: Function;
}

export default function MiniGallery(data : GalleryProps) {
    

    const [selectedImages, setSelectedImages] = useState<string[]>([])
    const [timerTriggeredImage, setTimerTriggeredImage] = useState<string>("");
    
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>();

    React.useEffect((): any => {
        
    }, [selectedImages.length]);


    function clicked(img: string) {
        
        if (!sessionService.IsAdmin())
        {
            data.clickfct(data.folder, img, data.images);
            return;
        }

        // execute only if admin (select & delete function)
        if (img == timerTriggeredImage)
        {
            setTimerTriggeredImage("");
            return;
        }
        if (selectedImages.length > 0)
        {
            let selecte = [...selectedImages];
            if (isSelected(img))
            {
                var index = selecte.indexOf(img);
                if (index !== -1) 
                {
                    selecte.splice(index, 1);             
                }
            }
            else
            {
                selecte.push(img);
            }
            setSelectedImages(selecte);
            
        }
        else
        {
            data.clickfct(data.folder, img, data.images);
        }
    }



    if (data.images.length <= 0)
    {
        return (
            <></>
        )
    }

    function handleDown(img : string)
    {    
        if (sessionService.IsAdmin())
        {
            const timer = setInterval(() => {
                setTimerTriggeredImage(img);
                setSelectedImages([img])
                clearInterval(timer);           
            }, 1000);
            
            setTimer(timer);
        }
    }
    
    function handleUp(img : string)
    {
        if (sessionService.IsAdmin())
        {
            clearInterval(timer);
        }
    }

    function isSelected(img: string)
    {
        return selectedImages.includes(img);
    }

    function handleDelete() {
        setShowDeleteModal(false);
        //console.log("DELETE THIS SHIT")
        //console.log(JSON.stringify({imgs: selectedImages}))
        RestInterface.deleteImages(data.stammtischId, selectedImages).then(resp => {
            data.imgagesDeletedCallback();
        }).catch(err => {
            
        });
        
    }

    //console.log("NUMBER OF IMAGES: " + data.images.length)

    return (
        <>
    <div className="gallery_container">
        <ul>
        {      
            data.images.map((img, idx) => (
                <li key={idx} className={isSelected(img) ? "selectedImgLi":""}><img className={isSelected(img) ? "selectedImg":""} src={data.folder + "/thumbnails/" + img} onMouseDown={() => handleDown(img)} onMouseUp={() => {handleUp(img)}} onMouseLeave={() => {}} onClick={() => {clicked(img)}}></img></li>
                //<li key={idx}><img src={data.folder + "/thumbnails/" + img} onMouseDown={} onMouseUp={} onClick={() => clicked(img)}></img></li>
            ))
        }
        {/*<li key={9998}><img src={"images/imgplus.gif"} onClick={() => data.imgPlusClicked()}></img></li>*/}
        <li key={9999}></li>
        </ul>
        
    </div>
    {selectedImages.length > 0 && <><Button variant="danger" onClick={() => setShowDeleteModal(true)}>Ausgewählte löschen</Button><br/></>}
    {showDeleteModal && 
        <Modal
        show={showDeleteModal}
        onHide={() => {setShowDeleteModal(false)}}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fotos löschen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Willst du die ausgewählten Fotos wirklick löschen?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => {setShowDeleteModal(false)}}>
            NICHT LÖSCHEN
          </Button>
          <Button variant="danger" onClick={handleDelete}>JA, LÖSCHEN</Button>
        </Modal.Footer>
      </Modal>
    }
    </>
    )
}