import { Badge, ListGroup } from "react-bootstrap";
import { Comment } from "../Data/RestResponseData";
import 'bootstrap/dist/css/bootstrap.min.css'
import { TimeUtil } from "../Services/TimeUtil";
import { TrashFill } from "react-bootstrap-icons";
import { RestInterface } from "../Services/RestInterface";
import { sessionService } from "../App";

export default function CommentEntry({ comment, delFct }: { comment: Comment, delFct: Function }) {
    





    return (
        
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start mb-1"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold CommentUsername">{comment.username}</div>
          {comment.text}
        </div>
        <Badge bg="secondary" pill>
          {TimeUtil.timestamptToCommentDate(comment.timestamp)}
        </Badge>

        { (sessionService.IsAdmin() || sessionService.getSelf()?.id === comment.userId) && <Badge as="div" onClick={() => {RestInterface.deleteComment(comment.id).then(resp => {delFct()}).catch(err => {/* empty */})}} className="CommentDeleter" bg="danger" pill>
                <TrashFill/>
            </Badge> }
        
      </ListGroup.Item>
     
    )
}