import React, {useRef, useState} from "react";
import { Form, Button } from "react-bootstrap";
import LoadingModal from "../Components/Modals/LoadingModal";
import { Navigate, useNavigate } from 'react-router-dom';

import "../css/createtisch.css";
import UserButtonSelectable from "../Components/UserButtonSelectable";
import { sessionService } from "../App";
import { StammtischCreateRequest } from "../Data/RestResponseData";
import { RestInterface } from "../Services/RestInterface";

interface Props {

}

export const CreateStammtisch: React.FC<Props> = () => {

    
 
    const [modalShow, setModalShow] = useState(false);
  
 

    const restaurantInput = useRef<HTMLInputElement>(null);
    const restaurantUrlInput = useRef<HTMLInputElement>(null);
    const dateInput = useRef<HTMLInputElement>(null);

    const [selected, setSelected] = useState<number[]>([]);

    let navigate = useNavigate();

    React.useEffect((): any => {
     
    }, []);


    function onCreate()
    {
      if (dateInput.current !== null && restaurantInput.current !== null && restaurantUrlInput.current !== null)
      {
        var mydate = new Date(dateInput.current.value);
        var utcMillis = mydate.getTime() + (18 * 3600000);
        var restaurant = restaurantInput.current.value;
        var url = restaurantUrlInput.current.value;
        var users: number[] = [];
        for(let i = 0; i < selected.length; i++)
        {
          users.push(selected[i]);
        }
        
        let creator: StammtischCreateRequest = {
          restaurant: restaurant,
          restaurantUrl: url,
          presentUsers: users,
          timestampOfDay: utcMillis
        };

        //console.log("request for creation of " + JSON.stringify(creator));

        setModalShow(true);
       
        
        RestInterface.createStammtisch(creator).then(resp => {
          //console.log("YAY");
          navigate("/Stammtische");
        }).catch(err => {
          //console.log("OH NO");
        }).finally(() => {
          setModalShow(false);
        });

        

      }
    }

    function onUserButtonClick(id: number)
    {
      //console.log("onClick=" + id);
      if (selected.includes(id))
      {
        let arr: number[] = [];
        for(let i = 0; i < selected.length; i++)
        {
          if (selected[i] !== id)
          {
            arr.push(selected[i]);
          }
        }
        setSelected(arr);
      }
      else
      {
        let arr: number[] = [];
        for(let i = 0; i < selected.length; i++)
        {
            arr.push(selected[i]);
        }
        arr.push(id);
        setSelected(arr);
      }
    }


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();


    }


  


    return(
        <div className="App-header">  
          <div style={{marginBottom: '2vh'}}>Stammtisch erstellen</div>
         <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control type="text" placeholder="Restaurant" ref={restaurantInput}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formRepeatPassword">
            <Form.Control type="url" placeholder="https://restaurant.de" autoComplete="off" ref={restaurantUrlInput}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formRepeatPassword">
            <Form.Control type="date" autoComplete="off" ref={dateInput}/>
          </Form.Group>
          
        </Form>
        <div className="SelectableUserButtonContainer">
        {
          sessionService.getAllUsers().map(u => (
            <UserButtonSelectable userId={u.id} username={u.username} size={100} selected={selected.includes(u.id)} onClick={() => onUserButtonClick(u.id)}></UserButtonSelectable>
          ))
        }
        </div>
        <Button variant="warning" onClick={onCreate} type="submit">
            Stammtisch erstellen
          </Button>
        <LoadingModal show={modalShow}></LoadingModal>
        </div>
      );
}