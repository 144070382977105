import { Accordion, Button, Card, Form, InputGroup, useAccordionButton } from "react-bootstrap";
import { LoadCommentsResponse, StammtischeResponse, UserResponse } from "../Data/RestResponseData";
import UserButton, { UserButtonProps } from "./UserButton";
import '../css/stammtische.css';
import '../css/minigallery.css';
import { TimeUtil } from "../Services/TimeUtil";
import MiniGallery from "./MiniGallery";

import { RestInterface } from "../Services/RestInterface";
import StammtischComments from "./StammtischCommentSection";
import { useRef, useState } from "react";
import { sessionService } from "../App";
import LoadingTextModal, { LoadingModalTextProps } from "./Modals/LoadingModalWithText";
import YesNoModal from "./Modals/YesNoModal";




type StammtischEntryProps = {
    stammtischData: StammtischeResponse,
    userData: UserResponse[],
    index: number,
    openId: number,
    onGalleryClicked: Function
}


function getUserButtonProps(userId: number, users: UserResponse[]) : UserButtonProps
{
    let usr = users.find(e => e.id === userId);
    if (usr)
    {
        return {userId: userId, username: usr.username, avatarHash: usr.avatarHash, size: 50};
    }
    return {userId: 0, username: "unknown", avatarHash: "", size: 50};
}

function CustomToggle({ children, eventKey }:{children:StammtischeResponse, eventKey:any}) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      {}
    );
    return (
        <div className="Header" onClick={decoratedOnClick}><div className="Header-Entry-Left"><b><a href={children.url} target="_blank">{children.restaurant}</a></b></div><div className="Header-Entry-Right"><b>{TimeUtil.timestampToDatestring(children.date)}</b></div></div>
    
    );
  }

  function removeNonAplh(input:string){
    const regex = /[^A-Za-z0-9]/g;
    const newStr = input.replace(regex, "");
    return newStr.substr(0, 10);
}

  function getStammtischFolder(restaurant: string, date: number)
{
    const folder = TimeUtil.timestampToCompactString(date) + "_" + removeNonAplh(restaurant).toLowerCase();
    return folder;
}


export default function StammtischEntry({ props }: { props: StammtischEntryProps }) {
    

    const [commentResponse, setCommentResponse] = useState<LoadCommentsResponse>({commentsWritten: 1, comments: []});
    const [commentsLoaded, setCommentsLoaded] = useState<boolean>(false);
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);
    const [stammtischImages, setStammtischImages] = useState<string[]>([]);
    const [numCommentsWritten, setNumCommentsWritten] = useState<number>(0);
    const [loadingTextModalProps, setLoadingTextModalProps] = useState<LoadingModalTextProps>({show: false, text: ""})
    const inputFile = useRef<HTMLInputElement>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);



     function uploadClicked(id: number)
    {     
      if (inputFile !== null && inputFile.current !== null)
        inputFile.current.click();
    }

    function setModalLoadingText(text: string) {
      setLoadingTextModalProps({show: loadingTextModalProps.show, text: text})
    }

    function onImageChange(event: React.ChangeEvent<HTMLInputElement>){
      if (event.target.files && event.target.files.length > 0) {
        setLoadingTextModalProps({show: true, text: "resizing images..."});
        RestInterface.uploadImagesResized(event.target.files, props.stammtischData.id, setModalLoadingText).then(resp => {
          setLoadingTextModalProps({show: false, text: "resizing images..."})   
          setImagesLoaded(false)      
        }).catch(err => {
          setLoadingTextModalProps({show: false, text: "resizing images..."})
        })
      }
    }
    

    function submitComment(stammtischId:number, text: string)
    {
      RestInterface.postComment(stammtischId, text).then(resp => {
        setCommentsLoaded(false);   
      })
    }

    const handleSubmit = (event: any) => {
      
              
      event.preventDefault();
      event.stopPropagation();

      
      if (event.target.Kommentarfeld.value !== "")
      {
        if (sessionService.isTokenExpired())
        {
          
          sessionService.updateToken().then(success => {
            submitComment(props.stammtischData.id, event.target.Kommentarfeld.value);
            event.target.Kommentarfeld.value = "";
          }).catch(error => {
            // empty
          });
        } 
        else
        {
          
          submitComment(props.stammtischData.id, event.target.Kommentarfeld.value);
          event.target.Kommentarfeld.value = "";
        }
      }

    };

    if (props.openId === props.stammtischData.id && !imagesLoaded)
    {
      RestInterface.loadImages(props.stammtischData.id).then(resp => {
        setStammtischImages(resp.data);
        setImagesLoaded(true);
      }).catch(err => {
        
      })
    }
    
    if (props.openId === props.stammtischData.id && !commentsLoaded)
    {

      // load comments
      RestInterface.loadComments(props.stammtischData.id).then(response => {
        
        setCommentResponse(response.data);
        setNumCommentsWritten(response.data.commentsWritten);
        setCommentsLoaded(true);
      }).catch(error => {
        
      }) 
    }

    const commentDeleted = () => {
      setCommentsLoaded(false);
    }

    function onImagesDeleted() {
      setImagesLoaded(false);
    }

    function deleteStammtisch(val: boolean) {
      if (val)
      {
        RestInterface.deleteStammtisch(props.stammtischData.id).then(success => {        
          window.location.reload();
        }).catch(error => {         
        })
      }
      setShowDeleteModal(false);
    }

    return (
        <>
        

            <Card style = {{marginBottom: '25px', backgroundColor: 'rgba(255,255,255,0.8)'}}>
            <Card.Header>
            <CustomToggle eventKey={String(props.stammtischData.id)}>{props.stammtischData}</CustomToggle>
            </Card.Header>
            
            <Accordion.Collapse eventKey={String(props.stammtischData.id)}>
              <Card.Body>         
                <MiniGallery {...{folder:"images/stammtische/" + getStammtischFolder(props.stammtischData.restaurant, props.stammtischData.date), images:stammtischImages, stammtischId: props.stammtischData.id, clickfct:props.onGalleryClicked, imgPlusClicked:uploadClicked, imgagesDeletedCallback: onImagesDeleted}}/>         
                <Button onClick={() => uploadClicked(props.stammtischData.id)}>Fotos uploaden</Button>
                <table className="commentTableDivider"><tbody><tr><td><hr/></td><td>Kommentare</td><td><hr/></td></tr></tbody></table>
                
                <StammtischComments commentResponse={commentResponse} delFct={commentDeleted} />
                
              {commentsLoaded && <Form className="mt-3 mb-2" onSubmit={handleSubmit}>
                  <InputGroup>
                    <Form.Control
                      placeholder={(numCommentsWritten > 0) ? "Nur 1 Kommentar pro Benutzer erlaubt":"Verfasse einen Kommentar"}
                      aria-label="CommentLabel"
                      name="Kommentarfeld"
                      autoComplete="off"
                      disabled={(numCommentsWritten > 0)}
                    />
                    <Button type="submit" variant="primary" disabled={(numCommentsWritten > 0)}>send</Button>                    
                  </InputGroup>
              </Form>}
              {/*sessionService.IsAdmin() && <Button variant="danger" onClick={() => {setShowDeleteModal(true);}}>Stammtisch löschen</Button>*/}
              </Card.Body>
            </Accordion.Collapse>
            
            <Card.Footer>
            <div>
            {props.stammtischData.presentUsers.map((u, index: number) => ( <UserButton key={index} data={getUserButtonProps(u, props.userData)}/> ))}
            </div>
            </Card.Footer>
            </Card>
            <LoadingTextModal props={loadingTextModalProps}></LoadingTextModal>
            {showDeleteModal && <YesNoModal props={{modalText:"Stammtisch wirklich löschen?", show:showDeleteModal, callback: deleteStammtisch}}></YesNoModal>}
            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept="image/*" onChange={onImageChange} multiple={true}/>
        </>
    )
}
