import React, {EffectCallback, ReactElement, useRef, useState} from "react";
import { Form, Button, Alert, Overlay, Modal, Spinner, Tooltip, Offcanvas, Tabs, Tab, Table } from "react-bootstrap";
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import "../css/adminpage.css";
import { RestInterface } from "../Services/RestInterface";
import { sessionService } from "../App";
import { LastLoginTimestamps, SendEmailRequest, StammtischeResponse } from "../Data/RestResponseData";
import UserButtonSelectable from "../Components/UserButtonSelectable";
import LoadingModal from "../Components/Modals/LoadingModal";
import UserButton from "../Components/UserButton";
import YesNoModal from "../Components/Modals/YesNoModal";

interface Props {

}

function EmailSender() {

  const [selected, setSelected] = useState<number[]>([]);
  const subjectRef = useRef<HTMLInputElement>(null);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [modalShow, setModalShow] = useState(false);
  const [infoShow, setInfoShow] = useState(false);
  const [infoText, setInfoText] = useState("");

  React.useEffect((): any => {
     var ids:number[] = [];
     sessionService.getAllUsers().forEach(u => {
       ids.push(u.id);
     })
     setSelected(ids);
  }, []);


  function resetAll() {
    var ids:number[] = [];
    sessionService.getAllUsers().forEach(u => {
       ids.push(u.id);
     })
     setSelected(ids);
     if (subjectRef.current !== null && textRef.current !== null)
     {
       subjectRef.current.value = "";
       textRef.current.value = "";
     }
  }

  function sendEmail()
  {  

    if (subjectRef.current !== null && textRef.current !== null && 
      subjectRef.current.value.length > 0 && textRef.current.value.length > 0)
    {
      let ids: number[] = [];
      for(let i = 0; i < selected.length; i++)
      {
          ids.push(selected[i]);
      }
      let request: SendEmailRequest = {
        recipientIds: ids,
        subject: subjectRef.current.value,
        text: textRef.current.value
      };
      setModalShow(true);

      
      RestInterface.sendEmail(request).then(resp => {
        setInfoText("Senden erfolgreich");
        resetAll();
      }).catch(err => {
        setInfoText("Fehler beim Senden! " + err);
      }).finally(() => {
        setModalShow(false);
        setInfoShow(true);
      })

    }
    
    
   
  }

  function onUserButtonClick(id: number)
    {
      //console.log("onClick=" + id);
      if (selected.includes(id))
      {
        let arr: number[] = [];
        for(let i = 0; i < selected.length; i++)
        {
          if (selected[i] !== id)
          {
            arr.push(selected[i]);
          }
        }
        setSelected(arr);
      }
      else
      {
        let arr: number[] = [];
        for(let i = 0; i < selected.length; i++)
        {
            arr.push(selected[i]);
        }
        arr.push(id);
        setSelected(arr);
      }
    }

  return (
    <>

      <div className="SelectableUserButtonContainer">
        {
          sessionService.getAllUsers().map(u => (

            <UserButtonSelectable key={u.id} userId={u.id} username={u.username} size={80} selected={selected.includes(u.id)} onClick={() => onUserButtonClick(u.id)}></UserButtonSelectable>
          ))
        }
      </div>

    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Control type="text" ref={subjectRef} placeholder="Betreff" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control as="textarea" placeholder="Nachricht" ref={textRef} rows={10} />
      </Form.Group>
    </Form>
    <Button variant="warning" onClick={sendEmail} ref={btnRef}>Senden</Button>
    <Modal show={infoShow} onHide={() => {setInfoShow(false);setModalShow(false);}}>       
        <Modal.Body>{infoText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {setInfoShow(false);setModalShow(false);}}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    <LoadingModal show={modalShow}></LoadingModal>
    </>
  );
}


function LastLoginData() {


  function GetDateString(timestamp: number)
  {
    if (timestamp <= 0)
      {
        return "keine Daten";
      } 
    return new Date(timestamp).toLocaleString();                 
  }
  

  return (<>
  <Table striped bordered hover  variant="dark">
  <thead>
                <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Letzter Login</th>
                </tr>
            </thead><tbody>
    {
      sessionService.getAllUsers().map((u, ix) => (
          <tr>
            <td><UserButton data={{userId: u.id, avatarHash: u.avatarHash, username: u.username, size: 50}}></UserButton></td>           
            <td>{u.username}</td>
            <td>{u.email}</td>
            <td>{GetDateString(u.lastSeen)}</td>   
          </tr>
      )) 
    }
    </tbody>
  </Table>
  </>);
}

function GetStammtischeList(tische: StammtischeResponse[], onDeleteClicked: Function )
{
 
  return (
    <>
      <Table striped bordered hover  variant="dark">
            <thead>
                <tr>
                <th>Datum</th>
                <th>Restaurant</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
            {
                tische.map(tisch => (
                    <tr key={tisch.id}>
                        
                        <td>{new Date(tisch.date).toLocaleString()}</td>
                        <td>{tisch.restaurant}</td>
                        <td><Button variant="danger" onClick={() => {onDeleteClicked(tisch.id)}}>löschen</Button></td>
                    </tr>
                ))
            
            }
            </tbody>
            </Table>
            
    </>
  );
}

export const AdminPage: React.FC<Props> = () => {

    const [modalShow, setModalShow] = useState(false);
    const [key, setKey] = useState('home');
    const [stammtische, setStammtische] = useState<StammtischeResponse[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [tischIdToDelete, setTischIdToDelete] = useState<number>(-1);

    React.useEffect((): any => {
      RestInterface.getAllStammtische().then(response => {
            
        
        setStammtische(response.data);
    
        
      }).catch(err => {
    
      });

    }, []);

    function onDeleteClicked(tischId: number)
    {
      setTischIdToDelete(tischId);
      setShowDeleteModal(true);
    }

    function deleteStammtisch(shouldDelete: boolean) {
        if (shouldDelete)
        {
          RestInterface.deleteStammtisch(tischIdToDelete).then(success => {        
            window.location.reload();
          }).catch(error => {         
          }).finally(() => {
            setTischIdToDelete(-1);
          })
        }
      setShowDeleteModal(false);
      
    }


    return(
      <>
        
          <div className="AdminMainContainer">
        <Tabs defaultActiveKey="userdata" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="home" title="Email senden">
              <div className="AdminEditContainer">{EmailSender()}</div>
            </Tab>
            <Tab eventKey="userdata" title="Benutzerdaten">
              <div className="AdminEditContainer">{LastLoginData()}</div>
            </Tab>
            <Tab eventKey="stammtische" title="Stammtische">
              <div className="AdminEditContainer">{GetStammtischeList(stammtische, onDeleteClicked)}</div>
            </Tab>
        </Tabs>
          
        </div>
     

        {showDeleteModal && <YesNoModal props={{modalText:"Stammtisch wirklich löschen?", show:showDeleteModal, callback: deleteStammtisch}}></YesNoModal>}
        </>
      );
}