
import '../css/userbutton.css'

type UserButtonSelectableProps = {
    userId: number;
    username: string;
    size: number;
    selected: boolean;
    onClick: Function;
}

export default function UserButtonSelectable(data: UserButtonSelectableProps) {
    
    const size = data.size + "px";

    const classname = data.selected ? "UserButtonContainerActive":"UserButtonContainer";

    return (
        <div className={classname} style={{width: size, height: size}} onClick={() => data.onClick()}>
            <img alt='Avatar' src={"images/users/" + data.userId + "/avatar.png"} />
            <div>{data.username}</div>
        </div>
    )
}
