import React from 'react'
import '../../css/custommodal.css'
import '../../css/customspinner.css'

export type LoadingModalTextProps = {
    show: boolean;
    text: string;
}   

export default function LoadingTextModal({props} : { props: LoadingModalTextProps }) {
    
    return (
     <> 
     { props.show && 
        <div className="outer">
            <div className="middle">
                <div className="inner"> 
                    <div className="loader"></div>
                    <div className="loaderText">{props.text}</div>
                </div>   
            </div>
        </div>
    }
    </>
    )
}
