import React, { useState } from "react";
import { RestInterface } from "../Services/RestInterface";
import { StammtischeResponse, UserResponse } from "../Data/RestResponseData";
import StammtischEntry from "../Components/StammtischEntry";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import '../css/stammtische.css';
import { PlusCircleFill, Search } from "react-bootstrap-icons";
import BigGallery, { BigGalleryProps } from "../Components/BigGallery";
import { useNavigate } from "react-router-dom";
import { sessionService } from "../App";
import BigGallery2 from "../Components/BigGallery2";


function MakeYearsUnique(yearArray: number[]) : number[]
{
    var tmp: number[] = [];
    yearArray.forEach((y) => { if (!tmp.includes(y)) { tmp.push(y); } })
    return tmp;
}


function Stammtische() {


    const [stammtischeResponse, setStammtischeResponse] = useState<StammtischeResponse[]>([]);
    const [userResponse, setUserResponse] = useState<UserResponse[]>([]);

    const [currentOpenId, setCurrentOpenId] = useState<number>(-1);
    const [filterText, setFilterText] = useState<string>("");
    const [filterYear, setFilterYear] = useState<number>(0);
    const [availableYears, setAvailableYears] = useState<number[]>();
    const [controlKeyPressed, setControlKeyPressed] = useState<boolean>(false);

    const [bigGalleryProps, setBigGalleryProps] = useState<BigGalleryProps>({show: false, folder: "", images: [], currentimage: "", closeFct: onBigGalleryClose, delFct: () => {}, tischId: -1});


    function handleKeyDown(event: any) {
        
        if (event.key === "Control")
        {
            setControlKeyPressed(true);
        }
    }

    function handleKeyUp(event: any) {
        
        if (event.key === "Control")
            {
                setControlKeyPressed(false);
            }
    }

    // const [totalPages, setTotalPages] = useState<number[]>([1]);
    React.useEffect((): any => {
        

        

        RestInterface.getUsers().then(response => {
            
            setUserResponse(response.data);
            
            //console.log(response.data);
        }).catch(err => {
            // nothing
        });
        RestInterface.getAllStammtische().then(response => {
            
            response.data.forEach(tisch => tisch.presentUsers = tisch.presentUsers.sort(function(a:number,b:number) {return a - b;}) )
            setStammtischeResponse(response.data);

            setAvailableYears(MakeYearsUnique(response.data.map(r => new Date(r.date).getFullYear())));

            
            //console.log(response.data);
        }).catch(err => {

        });

        document.addEventListener('keydown', handleKeyDown, true);
        document.addEventListener('keyup', handleKeyUp, true);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
        
    }, []);


    const onAcctordionChange = (key: any, object: any) => {
        
        if (key)
        {
            setCurrentOpenId(Number(key));       
        }
        else
        {
            setCurrentOpenId(-1);
        }
    }

    const filterTextChange = (event: any) => {
        const val:string = event.target.value;
        setFilterText(val);
    };

    const handleYearSelectChange = (event: any) => {
        //console.log(event.target.value);
        setFilterYear(event.target.value);
    }

    const checkIfFilterTrue = (stammtisch: StammtischeResponse): boolean => {
        const textTrue = stammtisch.restaurant.toLowerCase().includes(filterText.toLowerCase());
        const yearTrue = filterYear == 0 || filterYear == new Date(stammtisch.date).getFullYear();
        return textTrue && yearTrue;
    };

    function onImgDelete(img: string)
    {
        //console.log("onImgDelete: id=" + currentOpenId + ", img=" + img)
        RestInterface.deleteImage(currentOpenId, img);
    }

    function miniGalleryClicked(folderpath: string, image: string, images: string[]) {
        //console.log("FCKN CLICKED! " + image + ", folder=" + folderpath + ", numImgs=" + images.length);
        if (controlKeyPressed)
        {
            //console.log("crtl pressed, select image")
        }
        else
        {
            setBigGalleryProps({show: true, folder: folderpath, currentimage: image, images: images, closeFct: onBigGalleryClose, delFct: onImgDelete, tischId: currentOpenId});
        }
     }

     function onBigGalleryClose() {
        setBigGalleryProps({show: false, folder: "", images: [], currentimage: "", closeFct: onBigGalleryClose, delFct: () => {}, tischId: currentOpenId});
    }
    let navigate = useNavigate();
    
    return (
    
      
        <>
        
            
        <div id="FilterTable" className="mt-4">
        
        <table>
            <tbody>
                <tr>
                    <td>
                        <InputGroup>
                            <InputGroup.Text>Jahr</InputGroup.Text>
                            <Form.Select aria-label="Default select example" size="sm" onChange={handleYearSelectChange}>
                                <option value="0">Alle</option>
                                {availableYears?.map( y => <option key={y} value={"" + y}>{y}</option> )}
                            </Form.Select>
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <InputGroup.Text className="RestaurantSearch"><Search/>.</InputGroup.Text>
                            <Form.Control onChange={filterTextChange} size="sm" type="text" placeholder="Suchen" />
                        </InputGroup>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        
        <hr/>
        <div className="Stammtisch-Container"> 
            <Accordion className="StammtischEntry" onSelect={onAcctordionChange} flush>
                { stammtischeResponse.map((u, index:number) => ( checkIfFilterTrue(u) && <StammtischEntry key={u.id} props={{stammtischData: u, userData: userResponse, index: u.id, openId: currentOpenId, onGalleryClicked: miniGalleryClicked}}/>)) }
            </Accordion>
        </div>
        {bigGalleryProps.show && <BigGallery2 data={bigGalleryProps}></BigGallery2>}
        {sessionService.IsAdmin() && <div className="tischCreateButton"><Button size="lg" variant="warning" onClick={() => navigate('/CreateStammtisch')}><PlusCircleFill/><label id="TischErstellenLabel">&nbsp;Stammtisch erstellen</label></Button></div>}
        </>

    );

}


export default Stammtische;