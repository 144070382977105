
import { useState } from 'react';
import '../css/userbutton.css'
import { Button, Modal, Image } from 'react-bootstrap';
import { CircleFill, XLg } from 'react-bootstrap-icons';
import { sessionService } from '../App';

export type UserButtonProps = {
    userId: number;
    username: string;
    avatarHash: string;
    size: number;
}

export default function UserButton({ data }: { data: UserButtonProps }) {
    
    const size = data.size + "px";

    const [showPopup, setShowPopup] = useState<boolean>(false);

    // console.log()

    function calculatePercent(total: number, other: number) : number
    {
        return other / total;
    }

    function getAbsenceText(numAbsent: number) : string
    {
        if (numAbsent == 0)
        {
            return "null Mal"
        }
        if (numAbsent == 1)
        {
            return "ein Mal"
        }
        if (numAbsent == 2)
        {
            return "zwei Mal"
        }
        if (numAbsent == 3)
        {
            return "drei Mal"
        }
        if (numAbsent == 4)
        {
            return "vier Mal"
        }
        if (numAbsent == 5)
        {
            return "fünf Mal"
        }
        return numAbsent + " Mal";
    }

    function getAnwesenheitsText(percentPresent: number) : string
    {
        if (percentPresent > 95)
        {
            return "Das ist eine außergwöhnlich gute Stammtisch-Performance."
        }
        if (percentPresent > 89)
        {
            return "Das ist eine gute Stammtisch-Performance."
        }
        if (percentPresent > 79)
        {
            return "Das ist eine einigermaßen gute Stammtisch-Performance."
        }
        if (percentPresent > 69)
        {
            return "Das ist eine akzeptable Stammtisch-Performance."
        }
        if (percentPresent > 59)
        {
            return "Das ist eine verbesserungswürdige Stammtisch-Performance."
        }
        if (percentPresent > 40)
        {
            return "Das ist eine inakzeptable Stammtisch-Performance."
        }
        return "Absoluter Stammtisch-Underperformer. Wixxa.";
    }

    function getColor(value: number){
        
        var hue=((value * value * value) * 120).toString(10);
        return ["hsl(",hue,",100%,50%)"].join("");
    }

    function getUserContent() {

        let user = sessionService.getUserById(data.userId);
        
        if (user)
        {
            const percentPresent = Math.round(calculatePercent(user.presence[0], user.presence[1]) * 100);
            const percentAbsent = 100 - percentPresent;
            const widthText = percentPresent + "%";
            return (<>

            <div style={{border: "1px solid white", marginTop: "1rem", marginBottom: "2rem"}}>
                <div style={{width: widthText, height: "2rem", backgroundColor: getColor(calculatePercent(user.presence[0], user.presence[1]))}}></div>
            </div>

            {user.username} hat {user.presence[1]} von {user.presence[0]} möglichen Stammtischen besucht. Gefehlt hat er somit {getAbsenceText(user.presence[0] - user.presence[1])}, was
            einer Abwesenheitsquote von {percentAbsent}% entspricht. {getAnwesenheitsText(percentPresent)}
            
            
            </>);
        }
        return (<>
            
            </>);
    }




    return (
        <>
        
        <div className="UserButtonContainer" style={{width: size, height: size}} onClick={() => setShowPopup(true)}>
            <img alt='Avatar' src={"images/users/" + data.userId + "/avatar.png?" + data.avatarHash} />
            <div>{data.username}</div>
        </div>
        {
            showPopup && 
            
            <Modal
                show={showPopup}
                onHide={() => {setShowPopup(false)}}
                centered={true}
                keyboard={false}
            >
                <Modal.Header style={{backgroundColor: "#333", borderBottom: "none", display: "block"}}>
                <Modal.Title style={{color: "white", textAlign: "center"}} className="ms-auto">Das ist {data.username}, schau ihn dir an!</Modal.Title>
                <XLg color='orange' style={{position: "absolute", top: "5px", right: "5px", width: "35px", height: "35px", padding: "5px"}} cursor="pointer" onClick={() => {setShowPopup(false);}}/>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: "#333"}}>
                <div className="AvatarContainer" style={{display: "flex", justifyContent: "center"}}>
                    <Image className="AvatarImage" alt="Avatar" src={"images/users/" + data.userId + "/avatar.png?" + data.avatarHash} style={{height: "20vh"}} roundedCircle />
                </div>
                <div style={{fontSize: "1.0rem", color: "white", justifyContent: "center", textAlign: "center", padding: "1rem"}}>
                    {getUserContent()}
                </div>
                </Modal.Body>
                
            </Modal>
        }
        </>
    )
}
