
import './App.css';
import React, { useState } from 'react';
import KaslaibeNavBar from './Components/KaslaibeNavBar';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import Stammtische from './Pages/stammtische';
import { SessionService } from './Services/SessionService';
import { Profile } from './Pages/Profile';
import { CreateStammtisch } from './Pages/CreateStammtisch';
import { Statistik } from './Pages/Statistik';
import { Spinner } from 'react-bootstrap';
import { AdminPage } from './Pages/Admin';





//export const keycloakInstance = new Keycloak(initOptions);

export const sessionService = new SessionService();

function App() {

  
  const [sessionServiceInited, setSessionServiceInited] = useState(false);
  const [sessionServiceInitInitiated, setSessionServiceInitInitiated] = useState(false);
  const [sessionServiceFailed, setSessionServiceFailed] = useState(false);
  
  if (!sessionServiceInitInitiated)
  {

    sessionService.init().then(success => {
      setSessionServiceInited(true);
      sessionService.startTokenAutoRefresh();
    }).catch(err => {
      setSessionServiceFailed(true);
    });
    setSessionServiceInitInitiated(true);   
}



  React.useEffect((): any => {
          
         
  }, []);


  return (
    
      
      <>
         <div className="App" id="MainAppContainer">
         {sessionServiceInited && <React.StrictMode>
            <KaslaibeNavBar />
          <Routes>           
              <Route path="/Stammtische" element={<Stammtische />} />
              <Route path="/Profil" element={<Profile />} />
              <Route path="/CreateStammtisch" element={<CreateStammtisch />} />
              <Route path="/Statistik" element={<Statistik/>} />
              <Route path="/Admin" element={<AdminPage/>} />
              <Route path="*" element={<Navigate to="/Stammtische" replace />} />
          </Routes>
          </React.StrictMode>}
          {
            !sessionServiceInited && !sessionServiceFailed &&
            <div style={{height: "100%"}}>
            <Spinner animation="border" variant='warning' style={{position: "absolute", top:"40%", width: "4rem", height: "4rem" }}/>
            </div>
          }
          {
            sessionServiceFailed && 
            <div style={{color: "orange", textAlign: "center", verticalAlign: "middle", paddingTop: "20%", fontSize: "2rem"}}>! VERFICKTER FEHLER !</div>
          }
         </div>
         
      </>


    
  );

}

export default App;
