

import axios from "axios";
import { sessionService } from "./App";







const AxiosInstance = axios.create({
    baseURL: '',
    timeout: 10000,
    params: {} // do not remove this, its added to add params later in the config
});

AxiosInstance.interceptors.request.use(function (config) {
    //console.log("intercepted")
    config.headers['Authorization'] = 'Bearer ' + sessionService.getToken();
    return config;
}, function (error) {
    // Do something with request error
    window.location.reload();
    return Promise.reject(error)
})

AxiosInstance.interceptors.response.use(function (config) {
    //console.log("INTERCEPT RESPONSE SUCCESS: " + config);
    return config;
}, function(error) {

    
    //console.log("XX INTERCEPT RESPONSE ERROR " + error + ", status=" + error.response.status + ", tokenExpired? " + (sessionService.isTokenExpired()?"YES":"NO"))
    if (error.response)
    {
        if (error.response.status === 401)
        {
            window.location.reload();            
        }
    }
    
    
    return Promise.reject(error);
})

export default AxiosInstance