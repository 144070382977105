import { ListGroup } from "react-bootstrap";
import { LoadCommentsResponse } from "../Data/RestResponseData";
import CommentEntry from "./CommentEntry";
import "../css/comments.css";





export default function StammtischComments({ commentResponse, delFct } : { commentResponse: LoadCommentsResponse, delFct: Function }) {
    
    if (commentResponse === null || commentResponse === undefined || commentResponse.comments === null || commentResponse.comments.length === 0)
    {
        return (
            <div>               
                Keine Kommentare vorhanden
            </div>
        )
    }
    else
    {
        return (
            <ListGroup as="ol" className="CommentListGroup" numbered>
                {commentResponse.comments.map((c, index:number) => 
                
                
                    <CommentEntry key={index} comment={c} delFct={delFct} />
                
                )}
            </ListGroup>
        )
    }
    
}