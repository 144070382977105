import { AxiosResponse } from "axios";
import { GetUsersResponse, LastLoginTimestamps, LoadCommentsResponse, SendEmailRequest, StammtischCreateRequest, StammtischeResponse, StatistikResponse, UserResponse } from "../Data/RestResponseData";
import AxiosInstance from "../axios";
import { ImageResizer } from "./ImageResizer";






export class RestInterface {

    //process.env.NODE_ENV === 'development' ? dev : prod;
    static baseUrl = process.env.NODE_ENV === 'development' ? "http://localhost:8081/api/v1" : "https://www.kaslaibepatrol.de:8081/api/v1";

    static sendEmail(request: SendEmailRequest) : Promise<AxiosResponse<any,any>>
    {
      return AxiosInstance.post<any>(this.baseUrl + "/sendEmail", request, {headers: {
        "Content-Type": "application/json"
      }});
    }

    static getStatistics() : Promise<AxiosResponse<StatistikResponse>>
    {
      return AxiosInstance.get<StatistikResponse>(this.baseUrl + "/getStatistics", {headers: {
        "Content-Type": "application/json"
      }});
    }

    static triggerPasswordChange(userId: number): Promise<boolean>
    {
      var promise = new Promise<boolean>((resolve, reject) => {
          AxiosInstance.get<UserResponse[]>(this.baseUrl + "/ChangePassword?userId=" + userId, {headers: {
            "Content-Type": "application/json"
          }}).then(resp => {resolve(true);}).catch(error => { reject(false); });

      });
      return promise;
    }

    static getUsers(): Promise<AxiosResponse<UserResponse[]>>
    {
        //config.headers['Authorization'] = 'Bearer ' + KaslaibeUserInfo.token;
        return AxiosInstance.get<UserResponse[]>(this.baseUrl + "/LoadUsers", {headers: {
          "Content-Type": "application/json"
        }});
    }

    static getAllStammtische(): Promise<AxiosResponse<StammtischeResponse[]>>
    {
        
        //config.headers['Authorization'] = 'Bearer ' + KaslaibeUserInfo.token;
        return AxiosInstance.get<StammtischeResponse[]>(this.baseUrl + "/GetStammtische", {headers: {
          "Content-Type": "application/json"
        }});
    }

    static postComment(stammtischId: number, text: string)
    {
      var formData = new FormData();
      formData.append("stammtischId", "" + stammtischId);
      formData.append("comment", text);
      return AxiosInstance.post(this.baseUrl + "/comment", formData, {headers: {
        "Content-Type": "application/json"
      }});
    }

    static loadComments(stammtischId: number) : Promise<AxiosResponse<LoadCommentsResponse>>
    {
      return AxiosInstance.get<LoadCommentsResponse>(this.baseUrl + "/GetCommentsByStammtischId?stammtischId=" + stammtischId , {headers: {
        "Content-Type": "application/json"
      }});
    }

    static loadImages(stammtischId: number) : Promise<AxiosResponse<string[]>>
    {
      return AxiosInstance.get<string[]>(this.baseUrl + "/GetImages?stammtischId=" + stammtischId , {headers: {
        "Content-Type": "application/json"
      }});
    }
    
    static deleteComment(commentId: number)
    {
      var formData = new FormData();
      formData.append("commentId", "" + commentId);
      return AxiosInstance.post(this.baseUrl + "/deleteComment", commentId, {headers: {
        "Content-Type": "application/json"
      }});
    }

    static getAllUsers() : Promise<AxiosResponse<GetUsersResponse>>
    {
      return AxiosInstance.get<GetUsersResponse>(this.baseUrl + "/GetUsers" , {headers: {
        "Content-Type": "application/json"
      }});
    }

    static async uploadAvatar(image: File, userId: number)
    {
      var resized = await ImageResizer.resizeAvatar(image);
      var formData = new FormData();
      formData.append("userId", userId + "");
      formData.append("file", resized);
      return AxiosInstance.post(this.baseUrl + "/files/uploadAvatar", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',          
        }
      })
    }

    static createStammtisch(request: StammtischCreateRequest): Promise<AxiosResponse<any>>
    {
      return AxiosInstance.post<any>(this.baseUrl + "/createStammtisch", request, {headers: {
        "Content-Type": "application/json"
      }});
    }

    static deleteStammtisch(stammtischId: number): Promise<AxiosResponse<any>>
    {
      return AxiosInstance.get<GetUsersResponse>(this.baseUrl + "/deleteStammtisch?stammtischId=" + stammtischId , {headers: {
        "Content-Type": "application/json"
      }});
    }

    static async uploadImagesResized(images: FileList, tischId: number, loadingTextFct: Function)
    {
      for (let i = 0; i < images.length; i++)
      {
        //console.log("image=" + images[i].name + ", size=" + images[i].size);
      }

      var modDates: number[] = [];
      for (let i = 0; i < images.length; i++)
      {
        modDates.push(images[i].lastModified);
      }

      var resized = await ImageResizer.resizeAll(images);

      var formData = new FormData();
      var modifiedMap = new Map<string, number>();
      for (let i = 0; i < resized.length; i++)
      {
        formData.append("file", resized[i]);
        modifiedMap.set(resized[i].name, modDates[i])

      }
      formData.append("stammtischId", "" + tischId);
      formData.append("modifiedMap", JSON.stringify(Object.fromEntries(modifiedMap)));

      loadingTextFct("uploading images...");

      return AxiosInstance.post(this.baseUrl + "/files/uploadStammtischImages", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
    }

    static deleteImage(stammtischId: number, image: string) : Promise<boolean>
    {
      return new Promise((resolve, reject) => {
        AxiosInstance.get<GetUsersResponse>(this.baseUrl + "/deleteImage?stammtischId=" + stammtischId + "&img=" + image , {headers: {
          "Content-Type": "application/json"
        }}).then(resp => {
          resolve(true);
        }).catch(err => {
          reject(false);
        });
      });
    }

    static deleteImages(stammtischId: number, image: string[]) : Promise<boolean>
    {     
      return new Promise((resolve, reject) => {
        AxiosInstance.post<any>(this.baseUrl + "/deleteImages", {"stammtischId": stammtischId, "imgs": image} ,{headers: {
          "Content-Type": "application/json"
        }}).then(resp => {
          resolve(true);
        }).catch(err => {
          reject(false);
        });
      });
    }
}