import { Button, Image } from "react-bootstrap";
import '../css/profile.css';
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sessionService } from "../App";
import { RestInterface } from "../Services/RestInterface";

import AvatarCropModal from "../Components/Modals/AvatarCropModal";

interface Props {

}

export const Profile: React.FC<Props> = () => {

    const [showCrop, setShowCrop] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    
    const inputFile = useRef<HTMLInputElement>(null);
    
    
    let navigate = useNavigate();

    const routeChange = () =>{ 
        
        navigate("/");
        sessionService.logout();
    }

    function onClickChangeAvatar()
    {
        if(inputFile !== null && inputFile.current !== null)
        {
            inputFile.current.click()
        } 
    }


    /*
    function singleButtonCallback()
    {
        setSingleButtonModalProps({show: false, buttonText: "", modalText: "", callback: () => {}});
        sessionService.logout();
    }*/

    function onPwChangeClicked()
    {
        let user = sessionService.getSelf();
        if (user)
        {
            //setSingleButtonModalProps({show: true, buttonText: "Ok", modalText: "Du kannst dein Passwort nach dem nächsten Login ändern.", callback: singleButtonCallback})          
            RestInterface.triggerPasswordChange(user.id).then(success => {
                //setSingleButtonModalProps({show: true, buttonText: "Ok", modalText: "Du kannst dein Passwort nach dem nächsten Login ändern.", callback: singleButtonCallback})
                window.location.reload(); // triggers pw change re-direction to keycloak
            }).catch(err => {

            })
            
        }
    }


    function onImageChange(event: React.ChangeEvent<HTMLInputElement>){
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
        if (event.target.files && event.target.files[0] && acceptedImageTypes.includes(event.target.files[0].type)) {           
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
            setShowCrop(true);
        }
    }

    return (
        <>
        <div className="ProfileContainer"> 
            <div className="greetingContainer">Servus {sessionService.getSelf()?.username}, du Schwanz!</div>
            <div className="AvatarContainer">
                <Image className="AvatarImage" src={"images/users/" + sessionService.getSelf()?.id + "/avatar.png"} style={{}} roundedCircle />
            </div>

            <div><Button variant="warning" style={{marginTop: '1vh'}} onClick={onClickChangeAvatar}>Profilbild ändern</Button></div>

            <div><Button variant="warning" style={{marginTop: '1vh'}} onClick={onPwChangeClicked}>Passwort ändern</Button></div>

            <div><Button variant="warning" style={{marginTop: '3vh'}} onClick={routeChange}>Ausloggen</Button></div>
        </div>
        {
            <>
        <AvatarCropModal show={showCrop} image={selectedImage} onHide={() => {setShowCrop(false);}} />

        <input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept=".gif,.jpg,.jpeg,.png" onChange={onImageChange} multiple={false}/>
        </>
        /*<SingleButtonModal props={singleButtonModalProps} ></SingleButtonModal>*/
        /*<PasswordChangeModal show={showPwChange} onClose={() => setShowPwChange(false)}></PasswordChangeModal>
        
        <LoadingTextModal show={showModal} text={"processing..."}></LoadingTextModal>
        */
        }
        </>
    );
}

