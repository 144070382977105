import imageCompression from "browser-image-compression";

const options: any = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1920,
    useWenWorker: true
}

const avatarOptions: any = {
    maxSizeMB: 1,
    maxWidthOrHeight: 640,
    useWenWorker: true
}


export class ImageResizer {

    
    static async resizeAvatar(img: File){
        const compressed = await imageCompression(img, avatarOptions);
        return new File([compressed], img.name, {type: img.type});
       }

    static async resize(img: File){
       const compressed = await imageCompression(img, options);
       return new File([compressed], img.name, {type: img.type});
      }


      static async resizeAll(images: FileList): Promise<File[]>
      {
          var resized: File[] = [];
          for (let i = 0; i < images.length; i++)
          {
              var f = await imageCompression(images[i], options);
              resized.push(new File([f], images[i].name, {type: images[i].type}));
          }
          return resized;
      }

    static async cropImage(imgurl: string, x: number, y:number, w:number, h:number) : Promise<File>
    {
            var image = new Image();
            image.src = imgurl;

            var promise = new Promise<File>((resolve, reject) => {

                image.onload = () => {
                    var canvas = document.createElement("canvas");
                    canvas.width = w;
                    canvas.height = h;
                    
                    var context = canvas.getContext("2d");
                    context?.drawImage(image, -x, -y);
                    var file = this.dataURLtoFile(canvas.toDataURL(), "avatar.png");
                    if (file !== undefined)
                        resolve(file);
                    else
                        reject();
                };

            });

        
            return promise;
            
    }

    static dataURLtoFile(dataUrl: string, fileName: string) : File | undefined
    {
        var arr = dataUrl.split(',');
        if (arr !== null && arr[0] !== null)
            {
                var match = arr[0].match(/:(.*?);/);
                if (match !== null)
                {
            var mime = match[1];
            var bstr = atob(arr[1]);
            var n = bstr.length
            var u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
        
            return new File([u8arr], fileName, {type:mime});
        }
        }
        return undefined;
    }

}
