import React from 'react'
import '../../css/custommodal.css'
import { Button, Modal } from 'react-bootstrap';

type YesNoModalProps = {
    show: boolean;
    modalText: string;   
    callback: Function;
}

export default function YesNoModal({props} : {props: YesNoModalProps}) {
    
    function handleClose() {
        props.show = false;
    }

    return (
        <>
        <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
      >
        
        <Modal.Body>
         {props.modalText}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {props.callback(true)}}>
            JA
          </Button>
          <Button variant="secondary" onClick={() => {props.callback(false)}}>
            NEIN
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    );


  
}
