import React, { useState, useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Cropper from "react-easy-crop";
import LoadingTextModal from './LoadingModalWithText';
import { RestInterface } from '../../Services/RestInterface';
import { ImageResizer } from '../../Services/ImageResizer';
import { sessionService } from '../../App';

type AvatarCropProps = {
    show: boolean;
    image: string;
    onHide: Function;
}



export default function AvatarCropModal(data : AvatarCropProps) {
    
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedPixels, setCroppedPixels] = useState<any>(undefined);
    const [showModal, setShowModal] = useState(false);

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        
        setCroppedPixels(croppedAreaPixels);
    }, [])

    function onCropIt() {
        if (croppedPixels !== undefined)
        {
            catchOnHide();
            setShowModal(true);

            ImageResizer.cropImage(data.image, croppedPixels.x, croppedPixels.y, croppedPixels.width, croppedPixels.height).then(imgUrl => {
                //console.log("imgUrl=" + imgUrl);
               
                const userSelf = sessionService.getSelf();
                if (userSelf)
                {
                  RestInterface.uploadAvatar(imgUrl, userSelf.id).then(yay => {
                    catchOnHide();
                  }).catch(err => {
                    catchOnHide();
                  });
                }
                
            }).catch(error => {
                //console.log("ERROR CROPPING");
                catchOnHide();
            });
        }
    }

    function catchOnHide()
    {
        setShowModal(false);
        data.onHide();
    }

    return (
        <>
        <Modal
        {...{show: data.show, onHide: catchOnHide}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
                <div style={{height: '50vh'}}>
            <Cropper
                image={data.image}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape={"round"}
            /></div>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCropIt}>Übernehmen</Button>
          <Button onClick={catchOnHide}>Abbrechen</Button>
        </Modal.Footer>
      </Modal>
      <LoadingTextModal props={{show: showModal, text: "processing..."}} />
        </>
    );

}
