export class TimeUtil {


    static timestampToDatestring(millis: number): string
    {
        const date = new Date(millis);
        return date.getDate() + '. ' + date.toLocaleString('default', {month: 'long'}) + ' ' + date.getFullYear();
    }

    static timestampToCompactString(millis: number): string
    {
        const date = new Date(millis);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        
        const monthString = ((month < 10) ? ('0' + month) : '' + month);
        const dayString = ((day < 10) ? ('0' + day) : '' + day);
        return year + monthString + dayString;
    }

    static timestamptToCommentDate(millis: number)
    {
        const now = new Date();
        const date = new Date(millis);
        const diff = now.getTime() - millis;

        if (diff < 60*60000)
        {
            const minutes = Math.round(diff/60000);
            if (minutes === 1)
            {
                return "Vor " + Math.round(diff/60000) + " Minute";
            }
            return "Vor " + Math.round(diff/60000) + " Minuten";
        }
        else if (diff < 6*60*60000)
        {
            const hrs = Math.round(diff/(60 * 60000));
            if (hrs === 1)
            {
                return "Vor " + Math.round(diff/(60 * 60000)) + " Stunde";
            }
            return "Vor " + Math.round(diff/(60 * 60000)) + " Stunden";
        }
 
        return date.toLocaleString();
    }

}