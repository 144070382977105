import React, { useState } from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import {  useNavigate } from 'react-router-dom';
import { sessionService } from '../App';



export default function KaslaibeNavBar() {



    React.useEffect((): any => {
         
    }, []);

    var element = document.getElementById("MainAppContainer");
      if (element !== null)
      {
        element.style.backgroundImage = "url(images/bavaria.jpg)";
      }

    let navigate = useNavigate();

    
        
            return (
                
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                <Container>
                <Navbar.Brand href="/home"><img
                        alt=""
                        src="/android-icon-192x192.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        />{' '}KASLAIBEPATROL</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    <Nav.Link onClick={() => {navigate("/Stammtische")}}>Stammtische</Nav.Link>
                    <Nav.Link onClick={() => {navigate("/Statistik")}}>Statistik</Nav.Link>
                    {sessionService.IsAdmin() && <Nav.Link onClick={() => {navigate("/Admin")}}>Admin</Nav.Link>}
                    </Nav>
                    <Nav>
                    <Navbar.Text>
                        Eingeloggt als 
                    </Navbar.Text>
                    <Nav.Link onClick={() => {navigate("/Profil")}} style={{color: 'white'}}>{sessionService.getSelf()?.username}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                </Container>
                </Navbar>
            )


}