import { Table } from "react-bootstrap";

import '../css/profile.css';
import '../css/statistik.css';
import { useState } from "react";

import React from "react";
import UserButton from "../Components/UserButton";
import { sessionService } from "../App";
import { StatistikEntry, StatistikResponse, UserLight } from "../Data/RestResponseData";
import { RestInterface } from "../Services/RestInterface";
import { ArrowDownCircleFill, ArrowUpCircleFill } from "react-bootstrap-icons";


interface Props {

}

type Sorting = {
    active: boolean;
    desc: boolean;
}

function getUserButtons(users: number[], size: number) : any  
{
    if (users === undefined)
    {
        return(<></>);
    }

    return (<>
        {users.map(u => (
            //size={size} userId={u} username={sessionService.getUsernameById(u)
            <UserButton key={u} data={sessionService.getUserButtonProps(u)}></UserButton>
        ))}
    </>
    );
}




function getTable(sortingArray: Sorting[], clickedFunc: Function) : any  
{


    
    var userIds: number[] = [];
    var anw: string[] = [];
    var abw: string[] = [];
    var anwS: string[] = [];
    var abwS: string[] = [];



    let allUsers : UserLight[] = [...sessionService.getAllUsers()];

    if (sortingArray[0].active)
    {       
        allUsers.sort((a,b) => {
            return sortingArray[0].desc ? a.id - b.id : b.id - a.id;
        })
    }
    else if (sortingArray[1].active) // Sort by Anwesenheit
    {       
        allUsers.sort((a,b) => {
            const aPresent = a.presence[1] / a.presence[0];
            const bPresent = b.presence[1] / b.presence[0];
            return sortingArray[1].desc ?  bPresent - aPresent : aPresent - bPresent;
        })
    }
    else if (sortingArray[2].active) // Sort by Anwesenheit
    {       
        allUsers.sort((a,b) => {
            const aPresent = (a.presence[0] - a.presence[1]) / a.presence[0];
            const bPresent = (b.presence[0] - b.presence[1]) / b.presence[0];
            return sortingArray[2].desc ?  bPresent - aPresent : aPresent - bPresent;
        })
    }
    else if (sortingArray[3].active) // Sort by Anwesenheit
    {       
        allUsers.sort((a,b) => {
            
            return sortingArray[3].desc ?  b.presence[2] - a.presence[2] : a.presence[2] - b.presence[2];
        })
    }
    else if (sortingArray[4].active) // Sort by Anwesenheit
    {       
        allUsers.sort((a,b) => {
            
            return sortingArray[4].desc ?  b.presence[3] - a.presence[3] : a.presence[3] - b.presence[3];
        })
    }

    allUsers.forEach(user => {
        
        let numTotal = user.presence[0];
        let numAnwesend = user.presence[1];
        let numAbwesend = numTotal - numAnwesend;
        let anwesendStreak = user.presence[2];
        let abwesendStreak = user.presence[3];

        let presentPercent = Math.round((numAnwesend / numTotal) * 100);
        let absentPercent = 100 - presentPercent;

        userIds.push(user.id);
        anw.push(presentPercent + "% (" + numAnwesend + "/" + numTotal + ")");
        abw.push(absentPercent + "% (" + numAbwesend + "/" + numTotal + ")");
        anwS.push(anwesendStreak + "");
        abwS.push(abwesendStreak + "");
    });

    function getSortingButton(col: number)
    {
        const color: string = sortingArray[col].active ? "white" : "rgba(138, 138, 138, 0.589)"
        if (sortingArray[col].desc)
        {
            return (<>&nbsp;&nbsp;&nbsp;<ArrowDownCircleFill cursor={"pointer"} onClick={() => clickedFunc(col)} color={color}></ArrowDownCircleFill></>);
        }
        else
        {
            return (<>&nbsp;&nbsp;&nbsp;<ArrowUpCircleFill cursor={"pointer"} onClick={() => clickedFunc(col)} color={color}></ArrowUpCircleFill></>);
        }
    }


    return (<>
        <Table striped bordered hover  variant="dark">
            <thead>
                <tr>
                <th>User{getSortingButton(0)}</th>
                <th>Anwesend{getSortingButton(1)}</th>
                <th>Abwesend{getSortingButton(2)}</th>
                <th>Anwesend in Folge{getSortingButton(3)}</th>
                <th>Abwesend in Folge{getSortingButton(4)}</th>
                </tr>
            </thead>
            <tbody>
            {
                userIds.map((id, idx) => (
                    <tr>
                        <td>{getUserButtons([id], 50)}</td>
                        <td>{anw[idx]}</td>
                        <td>{abw[idx]}</td>
                        <td>{anwS[idx]}</td>
                        <td>{abwS[idx]}</td>
                    </tr>
                ))
            
            }
            </tbody>
            </Table>
    </>
    );

}


function getSpitzenreiterTable() : any  
{


    let bestPresentUserIds : number[] = [];
    let bestAbsentUserIds : number[] = [];
    let bestPresentStreakUsers : number[] = [];
    let bestAbsentStreakUsers : number[] = [];

    let bestPresentPercentage : number = 0;
    let bestAbsentPercentage : number = 0;
    let bestPresentStreak : number = 0;
    let bestAbsentStreak : number = 0;

    sessionService.getAllUsers().forEach(user => {
        let numTotal = user.presence[0];
        let numAnwesend = user.presence[1];
        let numAbwesend = numTotal - numAnwesend;
        let anwesendStreak = user.presence[2];
        let abwesendStreak = user.presence[3];

        let presentPercent = Math.round((numAnwesend / numTotal) * 100);
        let absentPercent = 100 - presentPercent;

        // Anwesend
        if (presentPercent > bestPresentPercentage)
        {
            // better performance
            bestPresentUserIds = [];
            bestPresentUserIds.push(user.id);
            bestPresentPercentage = presentPercent;
        }
        else if (presentPercent == bestPresentPercentage)
        {
            // same performance, add user
            bestPresentUserIds.push(user.id);
        }

        // Abwesend
        if (absentPercent > bestAbsentPercentage)
        {
            // better performance
            bestAbsentUserIds = [];
            bestAbsentUserIds.push(user.id);
            bestAbsentPercentage = absentPercent;
        }
        else if (absentPercent == bestAbsentPercentage)
        {
            // same performance, add user
            bestAbsentUserIds.push(user.id);
        }

        // Anwesend Streak
        if (anwesendStreak > bestPresentStreak)
        {
            // better performance
            bestPresentStreakUsers = [];
            bestPresentStreakUsers.push(user.id);
            bestPresentStreak = anwesendStreak;
        }
        else if (anwesendStreak == bestPresentStreak)
        {
            // same performance, add user
            bestPresentStreakUsers.push(user.id);
        }

        // Abwesend Streak
        if (abwesendStreak > bestAbsentStreak)
        {
            // better performance
            bestAbsentStreakUsers = [];
            bestAbsentStreakUsers.push(user.id);
            bestAbsentStreak = abwesendStreak;
        }
        else if (abwesendStreak == bestAbsentStreak)
        {
            // same performance, add user
            bestAbsentStreakUsers.push(user.id);
        }

    });

    return (
        <>
            <tr>
                <td>{getUserButtons(bestPresentUserIds, 100)}</td>
                <td>{getUserButtons(bestAbsentUserIds, 100)}</td>
                <td>{getUserButtons(bestPresentStreakUsers, 100)}</td>
                <td>{getUserButtons(bestAbsentStreakUsers, 100)}</td>
            </tr>
            <tr>
                <td><b>{(bestPresentPercentage).toString(10).substr(0, 2)}%</b> anwesend</td>
                <td><b>{(bestAbsentPercentage).toString(10).substr(0, 2)}%</b> abwesend</td>
                <td><b>{bestPresentStreak}x</b> in Folge anwesend</td>
                <td><b>{bestAbsentStreak}x</b> in Folge abwesend</td>
            </tr>
        </>
    );
}

export const Statistik: React.FC<Props> = () => {

  
     const [data, setData] = useState<StatistikResponse | undefined>(undefined);
     const [sorting, setSorting] = useState<Sorting[]>([{active: false, desc: true},{active: true, desc: true},{active: false, desc: true},{active: false, desc: true},{active: false, desc: true}]);

    React.useEffect((): any => {       
        RestInterface.getStatistics().then(resp => {
            
            setData(resp.data);
        }).catch(err => {
            //console.log("error loading statistics");
        });

    }, []);


    function sortingButtonClicked(numColumn: number) {
        console.log("sorting for column " + numColumn + " clicked")

        let newSorting : Sorting[] = [];
        let oldActive = 0;

        for(let i = 0; i < sorting.length; i++){
            newSorting.push({active: false, desc: sorting[i].desc});
            if (sorting[i].active)
            {
                oldActive = i;
            }
        }

        if (oldActive == numColumn)
        {
            newSorting[numColumn].desc = !newSorting[numColumn].desc;
        }
        newSorting[numColumn].active = true;
        setSorting(newSorting);
    }

    return (
        <>
        {data !== undefined && 
            <div className="SpitzenreiterContainer">

                <div style={{marginTop: '4vh', marginBottom: '2vh', fontSize: '2em', color: 'white'}}><b>Spitzenreiter</b></div>
            <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                <th>Anwesend</th>
                <th>Abwesend</th>
                <th>Anwesend Streak</th>
                <th>Abwesend Streak</th>
                </tr>
            </thead>
            <tbody>
                {getSpitzenreiterTable()}
                {/*
                <tr>
                    <td>{getUserButtons(data.bestPresentUsers, 100)}</td>
                    <td>{getUserButtons(data.bestAbsentUsers, 100)}</td>
                    <td>{getUserButtons(data.bestPresentStreakUsers, 100)}</td>
                    <td>{getUserButtons(data.bestAbsentStreakUsers, 100)}</td>
                </tr>
                <tr>
                    <td><b>{(data.bestPresentPercentage * 100).toString(10).substr(0, 2)}%</b> anwesend</td>
                    <td><b>{(data.bestAbsentPercentage * 100).toString(10).substr(0, 2)}%</b> abwesend</td>
                    <td><b>{data.bestPresentStreak}x</b> in Folge anwesend</td>
                    <td><b>{data.bestAbsentStreak}x</b> in Folge abwesend</td>
                </tr>
                */}
            </tbody>
            </Table>
            {getTable(sorting, sortingButtonClicked)}

            </div>
        }
        </>
    );
}

